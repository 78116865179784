<template>
	<div class="yunuo-header">
		<div class="yunuo-header-nav" id="nav" @mouseover="mouseNavOver($event)" @mouseleave="mouseNavLeave($event)">
			<div class="container yunuo-header-menu">
				<div class="fl-logo-box">
					<a class="yunuo-navigation-logo" @click="goPage('/Home')">
						<div class="logoBox"
							:style="'background: url('+ (logo?'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1666164061logo@2x.png':'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1664160424logo1@2x.png')+') no-repeat center;'">
						</div>
						<ul class="yunuo-navigation-slogan">
							<li :name="dataActive01">证券代码<br />837579</li>
							<li :name="dataActive02">
								持续推动医药大健康产业<br />数字经济转型
							</li>
						</ul>
					</a>
				</div>
				<div class="fr-nav-head">
					<ul class="clearfix">
						<li class="yunuo-nav-fir yunuo-preview" v-for="(item, idx) in items" :key="idx" :id="'li_'+idx"
							@mouseover="mouseOver($event)" @mouseleave="mouseLeave($event)" @click="goPage(item.path)">
							<!-- 产品一级栏目 -->
							<h3 class="yunuo-16">
								<a href="#" class="yunuo-data-text">{{ item.name }}</a>
							</h3>
							<div class="yunuo-nav-sec" v-if="item.subItems">
								<div v-for="(subItem, index) in item.subItems" :key="index"
									:class="'yunuo-flex y_'+index">
									<div class="fl header-left-nav">
										<ul class="yunuo-18">
											<!-- 产品下二级栏目1 -->
											<li class="active" @click="goPage(subItem.path)">
												<a href="javascript:void(0)">
													<p>{{ subItem.name }}</p>
													<span class="yunuo-12"></span>
												</a>
											</li>
										</ul>
									</div>
									<div class="fl header-right-nav" v-if="idx=='1'">
										<ul class="yunuo-18">
											<!-- 产品下二级栏目2 -->
											<li v-for="(liItem, index_s) in subItem.liItems" :key="index_s"
												@click="goPage(liItem.path)">
												<a href="javascript:void(0)">
													{{ liItem.name }}
													<span class="yunuo-12"></span>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ["isShowTop"],
		data() {
			return {
				inMenu: false,
				isShowTop: false,
				items: [{
						id: 1,
						name: "解决方案",
						isSubshow: true,
						subItems: [{
								path: "/EnterpriseSolution",
								name: "集团企业解决方案",
							},
							{
								path: "/MediumSolotion",
								name: "中大型企业解决方案",
							},
							{
								path: '/smallSolution',
								name: "小微企业解决方案",
							},
							{
								path: "/groupSolution",
								name: "医药新零售解决方案",
							},

						],
					},
					{
						id: 2,
						name: "产品中心",
						isSubshow: true,
						subItems: [{
								name: "企业管理类",
								liItems: [{
										path: "/ProductCenterGroup",
										name: "集团供应链云",
									},
									{
										path: "/ProductCenterStore",
										name: "门店云",
									},
									{
										path: "/ProductCenterChain",
										name: "连锁供应链云",
									},
									{
										path: "/ProductCenterInsurancel",
										name: "医保云",
									},
									{
										path: "/ProductCenterMicroenterprise",
										name: "小微供应链云",
									},
									{
										path: "/ProductCenterWms",
										name: "WMS",
									},
								],
							},
							{
								name: "新零售类",
								liItems: [{
										path: "/ProductCenterCrm",
										name: "会员CRM云",
									},
									{
										path: "/ProductCenterPrivate",
										name: "私域商城",
									},
									{
										path: "/ProductCenterOms",
										name: "OMS订单中台",
									}
								],
							},
							{
								name: "医药健康类",
								liItems: [{
										path: "/ProductCenterHospital",
										name: "医馆云",
									},
									{
										path: "/ProductCenterDtp",
										name: "DTP管理",
									},
									{
										path: "/ProductCenterNcd",
										name: "慢病管理",
									},
									{
										path: "/ProductCenterPrescription",
										name: "处方平台",
									}
								],
							},
						],
					},
					{
						id: 3,
						name: "生态合作",
						path: "/EcologicalCooperation",
						isSubshow: false,
					},
					{
						id: 4,
						name: "新闻中心",
						path: "/newsCenter",
						isSubshow: false,
					},
					{
						id: 5,
						name: "关于雨诺",
						isSubshow: true,
						subItems: [{
								name: "公司简介",
								path: "/AboutUs",
							},
							{
								name: "社会责任",
								path: '/SocialResponsibility'
							},
							{
								name: "联系我们",
								path: "/ContactUs"
							},
							{
								name: "人才招聘",
								path: '/TalentRecruitment'
							},
						],
					},
				],
				dataActive01: true,
				dataActive02: false,
				logo: false,

			};
		},
		mounted: function() {
			this.interval_id = setInterval(() => {
				if (this.dataActive01 || !this.dataActive02) {
					this.dataActive01 = false
					this.dataActive02 = true
				} else {
					this.dataActive01 = true
					this.dataActive02 = false
				}
			}, 3000)
			window.addEventListener('scroll', this.handleScrolls) // 监听页面滚动
		},
		created() {
			this.$router.beforeEach((to, from, next) => {
				var str = to.name;
				var isShowTop = str.indexOf('NewsInfor') != -1 || str.indexOf('NewsCenter') != -1;
				this.isShowTop = isShowTop
				if (isShowTop) {
					document.getElementById("nav").className = "yunuo-header-nav active";
					this.logo = true;
				}
				next()
			})
		},
		methods: {
			handleScrolls() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (this.isShowTop) {
					document.getElementById("nav").className = "yunuo-header-nav active";
					this.logo = true;
				} else {
					if (scrollTop > 75) {
						document.getElementById("nav").className = "yunuo-header-nav active";
						this.logo = true;
					} else {
						if (this.inMenu) {
							document.getElementById("nav").className = "yunuo-header-nav active";
						} else {
							document.getElementById("nav").className = "yunuo-header-nav";
							this.logo = false;
						}

					}
				}
			},
			goPage(src) {
				if (src) {
					this.$router.push(src)
					let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
					var timer = setInterval(() => {
							document.documentElement.scrollTop = document.body.scrollTop = scrollTop -=
							500; //一次减50往上滑动
							if (scrollTop <= 0) {
								clearInterval(timer);
							}
						},
						10);
				}
			},
			//头部鼠标悬停事件
			mouseNavOver($event) {
				$event.currentTarget.className = "yunuo-header-nav active";
				this.inMenu = true;
				this.logo = true;
			},
			mouseNavLeave($event) {
				this.inMenu = false;
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

				if (!this.isShowTop) {
					$event.currentTarget.className = "yunuo-header-nav";
					this.logo = false;
				}
				if (scrollTop > 10) {
					$event.currentTarget.className = "yunuo-header-nav active";
					this.logo = true;
				}
			},

			//菜单鼠标悬停事件
			mouseOver($event) {
				this.inMenu = true;
				$event.currentTarget.className = "yunuo-nav-fir yunuo-preview active";
			},
			mouseLeave($event) {
				this.inMenu = false;
				$event.currentTarget.className = "yunuo-nav-fir yunuo-preview";
			}
		},
	};
</script>
<style scoped>
	.yunuo-navigation-slogan>li {
		list-style: none;
		opacity: 0;
		margin: auto;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.2s ease-out 0s;
	}

	.yunuo-navigation-slogan>li[name=true] {
		opacity: 1;
		transition-delay: .6s;
	}

	.yunuo-navigation-slogan>li[name=true] {
		opacity: 1;
		transition-delay: .6s;
	}

	.yunuo-header {
		width: 100%;
		/*  height: 75px; */
	}

	.yunuo-header .yunuo-header-menu {
		display: flex;
		align-items: center;
		position: relative;
		height: 75px;
	}

	.yunuo-header-nav {
		height: 75px;

		z-index: 1000;
	}

	.yunuo-header .yunuo-header-nav {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		transition: all 0.2s ease-out 0s;
	}

	.yunuo-header .yunuo-header-nav.active {
		background: #fff;
		box-shadow: 0 1px 20px rgb(54 54 54 / 40%);
	}

	.fl-logo-box {
		display: flex;
		align-items: center;
		height: 75px;
	}

	.yunuo-navigation-logo {
		display: inline-flex;
		align-items: center;
		margin-right: 4px;
		overflow: visible;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.logoBox {
		width: 179px;
		height: 26px;
	}

	.yunuo-navigation-slogan {
		margin-left: 38px;
		font-size: 14px;
		font-weight: 400;
		color: #ffffff;
		line-height: 20px;
		flex: 1 1;
		white-space: nowrap;
		position: relative;
		list-style: none;
		transition: color .3s ease;
		display: block;
		z-index: 999999;
		overflow: hidden;
		height: 75px;
		width: 154px;
	}

	.yunuo-header .yunuo-header-nav.active .fl-logo-box>a ul li {
		color: #969799;
	}

	.fr-nav-head {
		flex: 1;
	}

	.fr-nav-head>ul {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
	}

	.yunuo-nav-fir {
		padding: 0 35px;
		box-sizing: border-box;
	}

	.yunuo-nav-fir h3 {
		padding: 0 5px;
		line-height: 73.5px;
		/* line-height: 75px; */
		position: relative;
		flex: 1;
		transition: all 0.2s ease-out 0s;
	}

	.yunuo-nav-fir.yunuo-preview.active h3>a {
		color: #252b3a;
	}

	.yunuo-nav-fir.yunuo-preview.active h3:after {
		content: "";
		width: 57px;
		display: block;
		height: 2px;
		background: #2386ee;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: all 0.2s;
	}

	.yunuo-data-text {
		font-size: 20px;
		font-weight: 400;
		color: #ffffff;
		transition: all 0.2s ease-out 0s;
	}

	.yunuo-header-nav.active .yunuo-header-menu .fr-nav-head ul li>h3 .yunuo-data-text {
		color: #222932;
	}

	.yunuo-nav-sec {
		display: none;
		position: absolute;
		left: 245px;
		width: auto;
		height: auto;
		background: #ffffff;
		box-shadow: 0px 3px 6px 0px rgb(220 220 220 / 54%);
		padding-bottom: 25px;
		padding-top: 30px;
		transition: all 0.2s ease-out 0s;
	}

	.fr-nav-head>ul li.yunuo-nav-fir:nth-child(2) .yunuo-nav-sec {
		width: 476px;
		right: auto;
		left: 32%;
	}

	.fr-nav-head>ul li.yunuo-nav-fir:nth-child(3) .yunuo-nav-sec {
		left: auto;
		right: 31.5%;
	}

	.fr-nav-head>ul li.yunuo-nav-fir:nth-child(4) .yunuo-nav-sec {
		left: auto;
		right: 17.5%;
	}

	.fr-nav-head>ul li.yunuo-nav-fir:last-child .yunuo-nav-sec {
		left: auto;
		right: 1%;
	}

	.yunuo-nav-fir.yunuo-preview.active .yunuo-nav-sec {
		display: block;
	}

	.header-left-nav {
		padding: 0px 35px;
	}

	#li_1 .yunuo-nav-sec .yunuo-flex .header-left-nav {
		width: 100px;
		padding-left: 59px;
		padding-right: 23px;

	}

	#li_1 .yunuo-nav-sec .yunuo-flex {
		display: flex;
		margin-bottom: 40px;
	}

	.header-left-nav ul {
		width: 100%;
		display: inline-block;
		vertical-align: top;
	}

	.header-left-nav ul li {
		line-height: 43.7px;
		text-align: center;
	}

	#li_1 .yunuo-nav-sec .yunuo-flex .header-left-nav ul li {
		height: 100px;
		text-align: left;
	}

	#li_1 .yunuo-nav-sec .yunuo-flex .header-left-nav ul li>a {
		color: #222932;
	}

	#li_1 .yunuo-nav-sec .yunuo-flex .header-left-nav ul li {
		line-height: initial;
	}

	.yunuo-nav-sec .yunuo-flex .header-left-nav ul li:hover a {
		color: #2a84ee;
	}

	.header-left-nav ul li>a {
		font-size: 18px;
		font-weight: 400;
		color: #777777;
	}

	.header-right-nav {
		width: 294px;
		background: white;
		height: auto;
		overflow: hidden;
	}

	.header-right-nav ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		position: relative;
	}

	.header-right-nav ul::after {
		content: "";
		position: absolute;
		width: 2px;
		display: block;
		left: 0;
		height: 115px;
		background: rgba(119, 119, 119, 0.5);
	}

	.header-right-nav ul li {
		width: 50%;
		height: 35px;
		padding-left: 20px;
		box-sizing: border-box;
	}

	.header-right-nav ul li>a {
		font-size: 16px;
		font-weight: 400;
		color: #777777;
	}

	#li_1 .yunuo-nav-sec {
		padding-bottom: 15px;
		padding-top: 37px;
	}

	.yunuo-flex {
		display: flex;
	}

	#li_1 .yunuo-nav-sec .yunuo-flex.y_2 {
		margin-bottom: 0;
	}

	#li_1 .yunuo-nav-sec .yunuo-flex .header-right-nav ul li:hover a {
		color: #2a84ee;
	}
</style>
