<template>
	<li @click="goPage(data.path)">
		<h2>{{data.title}}</h2>
		<div class="news-more">
			<span>{{data.time}}</span>
			<p>
				<span>查看更多</span>
				<div class="img"></div>
			</p>
		</div>
	</li>
</template>

<script>
	export default {
		props: ["data"],
		name: 'ListBox',
		data() {
			return {};
		},
		methods: {
			goPage(src) {
				this.$router.push(src)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			}
		}
	};
</script>

<style scoped>
	

	li {
		padding: 32px 0;
		border-bottom: 1px solid #DCDCDC;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}

	li:nth-child(1) {
		padding-top: 0px;
	}

	li h2 {
		font-size: 20px;
		font-weight: 400;
		color: #222932;
		line-height: 20px;
		width: 500px;
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
		transition: all 0.2s ease-out 0s;
	}
	
	li:hover h2 {
		color: #1482EE;
	}

	li .news-more>span {
		display: block;
		font-size: 20px;
		font-weight: 400;
		color: #777777;
		line-height: 20px;
		margin-bottom: 14px;
		transition: all 0.2s ease-out 0s;
	}
	
	li:hover .news-more>span {
		color: #1482EE;
	}

	li .news-more p {
		display: flex;
		align-content: center;
		align-items: center;
	}
	
	li .news-more p span {
		font-size: 14px;
		font-weight: 400;
		color: #777777;
		line-height: 14px;
		margin-right: 7px;
		transition: all 0.2s ease-out 0s;
	}
	li:hover .news-more p span {
		color: #1482EE;
	}

	li .news-more p .img {
		display: block;
		width: 11px;
		height: 9px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16653906121.png) no-repeat center;
		background-size: cover;
		transition: all 0.2s ease-out 0s;
	}
	li:hover .news-more p .img {
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16653906312.png) no-repeat center;
		background-size: cover;
	}
</style>
