import { createStore } from 'vuex'

export default createStore({
  namespaced: true,
  state: {
    imgLogs: []
  },
  getters: {
  },
  mutations: {
    imgList(state,data) {
      state.imgLogs = data
    }
  },
  actions: {
  },
  modules: {
  }
})
