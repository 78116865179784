<template>
	<div class="footer">
		<div class="yunuo-footer">
			<div class="container">
				<div class="yunuo-content">
					<div class="footer-left fl">
						<div class="ewmBox weixinBox" v-show="choaseNum==1">
							<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636392261.jpg" alt="">
							<p>关注雨诺公众号</p>
						</div>
						<div class="ewmBox shipinBox" v-show="choaseNum==2">
							<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636392392.png" alt="">
							<p>关注雨诺视频号</p>
						</div>
						<img class="footer-left-logo"
							src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1661926368yunuo-logo.png"
							alt="" />
						<p class="footer-contact-title hotline">咨询热线：</p>
						<!-- <a href="tel:0532-85886309" class="data_collect" data-title="咨询热线"> -->
							<p class="footer-contact-tel">0532-85886309</p>
						<!-- </a> -->

						<p class="footer-contact-title email">邮箱：</p>
						<!-- <a href="#" class="data_collect" data-title="邮箱："> -->
							<p class="footer-contact-email">romens@romens.cn</p>
						<!-- </a> -->
						<div class="footer-share-logo-box">
							<p class="footer-contact-title follow">关注我们</p>
							<div class="clearfix list_sh">
								<div class="fl">
									<!-- <a href="#" target="_blank" class="data_collect" data-title="微信"> -->
										<img class="footer-share-er"
											:src="choaseNum==1?'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1661927656wx.png':'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16654564951.png'"
											alt="" @mouseover="mouseNavOver($event,1)" @mouseleave="mouseNavLeave($event)" />
									<!-- </a> -->
								</div>
								<div class="fl">
									<!-- <a href="#" target="_blank" class="data_collect" data-title="视频号"> -->
										<img class="footer-share-er"
											:src="choaseNum==2?'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16654565872.png':'https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16654566743.png'"
											alt="" @mouseover="mouseNavOver($event,2)" @mouseleave="mouseNavLeave($event)" />
									<!-- </a> -->
								</div>
							</div>
						</div>
					</div>
					<div class="yunuo-content-list">
						<div class="yunuo-content-product" v-for="(item,index) in items" :key="index" :class="item.subItems.length>7?'special': ''">
							<ul>
								<li class="yunuo-content-product-title">{{item.name}}</li>
								<li class="yunuo-content-product-menu" v-for="(items,idx) in item.subItems" :key="idx" @click="goPage(items.path)">{{items.name}}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="yunuo-copyright">
					<div class="yunuo-copyright-item">
						<span>Copyright © 2002-2023 青岛雨诺网络信息股份有限公司</span>
						<span class="yunuo-lf">版权所有</span>
						<span class="yunuo-lf yunuo-rt"><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备10012854号-1</a></span>
						<img src="http://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1668480121jh.png" />
						<span><a target="_blank" href="https://www.beian.gov.cn/portal/index.do">鲁公网安备 37020202000070号</a></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				choaseNum: null,
				items: [
					{name: '解决方案', 
					subItems: [
						{name: '集团企业解决方案',path: '/EnterpriseSolution'},
						{name: '中大型企业解决方案',path: '/MediumSolotion'},
						{name: '小微企业解决方案',path: '/smallSolution'},
						{name: '医药新零售解决方案',path: '/groupSolution'}
					]},
					{name: '产品中心',
					subItems: [
						{name: '集团供应链云',path: '/ProductCenterGroup'},
						{name: 'OMS订单中台',path: '/ProductCenterOms'},
						{name: '连锁供应链云',path: '/ProductCenterChain'},
						{name: '私域商城',path: '/ProductCenterPrivate'},
						{name: '小微供应链云',path: '/ProductCenterMicroenterprise'},
						{name: '医馆云',path: '/ProductCenterHospital'},
						{name: '门店云',path: '/ProductCenterStore'},
						{name: '慢病管理',path: '/ProductCenterNcd'},
						{name: '医保云',path: '/ProductCenterInsurancel'},
						{name: 'DTP管理',path: '/ProductCenterDtp'},
						{name: 'WMS',path: '/ProductCenterWms'},
						{name: '处方平台',path: '/ProductCenterPrescription'},
						{name: '会员CRM云',path: '/ProductCenterCrm'}
					]},
					{name: '生态合作',
					subItems: [
						{name: '生态合作',path: '/EcologicalCooperation'}
					]},
					{name: '新闻中心',
					subItems: [
						{name: '新闻中心',path: '/newsCenter'}
					]},
					{name: '关于雨诺',
					subItems: [
						{name: '公司简介',path: '/AboutUs'},
						{name: '社会责任',path: '/SocialResponsibility'},
						{name: '联系我们',path: '/ContactUs'},
						{name: '人才招聘',path: '/TalentRecruitment'}
					]}
				],
			}
		},
		mounted: function() {
			
		},
		methods: {
			goPage(src) {
				this.$router.push(src)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			},
			//头部鼠标悬停事件
			mouseNavOver($event,index) {
				this.choaseNum = index;
				
			},
			mouseNavLeave($event) {
				this.choaseNum = null;
			}
		},
	};
</script>
<style scoped>
	/* 底部 */
	.yunuo-footer {
		min-width: 1400px;
		background: #141b1e;
	}

	.yunuo-content {
		width: 100%;
		height: auto;
		margin: 0px 0px 44px 0px;
		display: flex;
		align-items: flex-start;
		color: #fff;
		flex-wrap: wrap;
		padding-top: 55px;
	}

	.yunuo-content-list {
		flex: 1;
		height: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.yunuo-content-product {
		/* margin-right: 59px; */
		/* width: 25%; */
		/* flex: 1; */
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-size: 14px;
	}

	.yunuo-content-list .yunuo-content-product:nth-child(1) {
		width: 182px;

	}

	.yunuo-content-list .yunuo-content-product:nth-child(2) {
		width: 256px;
	}

	.yunuo-content-list .yunuo-content-product:nth-child(3) {
		width: 131px;

	}

	.yunuo-content-list .yunuo-content-product:nth-child(4) {
		width: 131px;

	}

	.yunuo-content-list .yunuo-content-product:nth-child(5) {
		width: 131px;
	}

	.yunuo-content-product.special>ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		position: relative;
	}

	.yunuo-content-product.special>ul li.yunuo-content-product-menu {
		width: 50%;
	}

	.yunuo-content-product-title {
		margin-bottom: 35px;
		font-size: 20px;
		font-weight: 500;
		color: #ffffff;
		width: 100%;
	}

	.yunuo-content-product-menu {
		margin-bottom: 21px;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
		color: #adadad;
		transition: all 0.2s linear 0s;
	}
	.yunuo-content-product-menu:hover {
		color: #ffffff;
		/* transform: scale(1.05); */
	}

	.yunuo-content-product-menu.special {
		width: 50%;
	}

	.yunuo-content-wx {
		width: initial;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-right: 57px;
		box-sizing: border-box;
	}

	.yunuo-content-appicon {
		width: 179px;
		height: 55px;
	}

	.yunuo-content-phone {
		margin: 30px 0 30px;
		display: flex;
		flex-direction: column;
		line-height: 30px;
	}

	.yunuo-content-boxs {
		margin-top: 20px;
	}

	.yunuo-content-txt {
		display: flex;
		align-items: center;
	}

	.yunuo-content-txt span {
		font-size: 20px;
		font-weight: bold;
		color: #ffffff;
	}

	.yunuo-content-phone span {
		font-size: 15px;
		font-weight: 500;
		color: #9c9c9f;
	}

	.yunuo-content-qrcode {
		width: 105px;
		height: 105px;
	}

	.yunuo-content-text {
		width: 105px;
		margin-top: 10px;
		text-align: center;
		font-size: 14px;
		color: #9c9c9f;
	}

	.yunuo-copyright,
	.yunuo-copyright-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.yunuo-copyright {
		width: 100%;
		height: 149px;
		flex-direction: column;
		color: #999;
		font-size: 14px;
		border-top: 1px solid #4b5054;
	}

	.yunuo-lf {
		margin-left: 20px;
	}

	.yunuo-rt {
		margin-right: 20px;
	}

	.yunuo-copyright,
	.yunuo-copyright-item {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.yunuo-copyright,
	.yunuo-copyright-item {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		line-height: 30px;
	}

	.yunuo-copyright-item img {
		width: 20px;
		height: 20px;
		margin: 0 5px;
	}

	.yunuo-list-01 {
		background: #ffffff;
	}

	.yunuo-server-title {
		position: relative;
		padding-bottom: 80px;
		text-align: center;
	}

	.yunuo-server-title>h2 {
		font-size: 32px;
		padding-top: 100px;
		padding-bottom: 20px;
		font-weight: bold;
		color: #222a32;
	}

	.yunuo-server-title>p {
		font-size: 16px;
		font-weight: 500;
		color: #666666;
	}

	.yunuo-server {
		width: 1400px;
		margin: 50px auto 0px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.yunuo-server .yjk-server-box {
		width: 20%;
		cursor: pointer;
		margin-bottom: 100px;
		text-align: center;
	}

	.yunuo-server-box .yjk-server-img {
		width: 160px;
		height: 160px;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.yunuo-server-box h1 {
		color: #000000;
		font-weight: normal;
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		margin-bottom: 15px;
	}

	.yunuo-server-box p {
		font-size: 13px;
		font-weight: 500;
		color: #999999;
	}

	.yunuo-list-02 {
		width: 100%;
		height: inherit;
		background: #f9fafe;
	}

	.yunuo-cooperate-title {
		position: relative;
		padding-bottom: 80px;
		text-align: center;
	}

	.yunuo-cooperate-title>h2 {
		padding-top: 100px;
		padding-bottom: 20px;
		font-size: 32px;
		font-weight: bold;
		color: #333333;
	}

	.yunuo-cooperate-title>p {
		font-size: 16px;
		font-weight: 500;
		color: #666666;
	}

	.yunuo-cooperate {
		width: 1400px;
		margin: 0px auto;
		height: 349px;
		border-radius: 35px;
		opacity: 1;
		background: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		margin-bottom: 100px;
	}

	.yunuo-cooperate-ul {
		width: 1400px;
		height: 234px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		box-sizing: border-box;
	}

	.yunuo-cooperate-ul .yunuo-cooperate-li {
		cursor: pointer;
		text-align: center;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.yunuo-cooperate-li h1 {
		font-weight: normal;
		font-size: 60px;
		font-weight: bold;
		color: #508aff;
	}

	.yunuo-cooperate-li p {
		font-size: 18px;
		font-weight: 500;
		color: #666666;
		margin-top: 12px;
	}

	.yunuo-list-03 {
		background: #ffffff;
	}

	.yunuo-marketing-title {
		position: relative;
		padding-bottom: 80px;
		text-align: center;
	}

	.yunuo-marketing-title>h2 {
		font-size: 32px;
		padding-top: 100px;
		padding-bottom: 20px;
		font-weight: 500;
		color: #222a32;
	}

	.yunuo-marketing-title>p {
		font-size: 16px;
		font-weight: 500;
		color: #666666;
	}

	.yunuo-marketing {
		width: 1400px;
		background: #ffffff;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		padding-bottom: 100px;
		box-sizing: border-box;
	}

	.yunuo-marketing-box05 {
		display: flex;
		align-items: center;
		padding-bottom: 110px;
		padding-top: 120px;
		width: 100%;
		justify-content: space-between;
	}

	.yunuo-marketing-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.yunuo-marketing-img {
		width: 610px;
		height: 500px;
		padding-left: 50px;
	}

	.yunuo-dtp-img {
		width: 610px;
		height: 500px;
		padding-right: 50px;
	}

	.yunuo-oms-img {
		width: 610px;
		height: 500px;
		padding-right: 50px;
	}

	.yunuo-marketing-lf>h1 {
		font-size: 32px;
		font-weight: bold;
		color: #222a32;
		margin-bottom: 35px;
	}

	.yunuo-marketing-lf>h1 div {
		margin-bottom: 5px;
	}

	.yunuo-marketing-lf p {
		font-size: 16px;
		font-weight: 500;
		color: #999999;
	}

	.yunuo-marketing-lf {
		padding-right: 100px;
		width: 390px;
	}

	.yunuo-cooperate-btn {
		width: 100%;
		height: 115px;
		text-align: center;
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	.yunuo-marketing-btn {
		margin-top: 50px;
		width: 100%;
		text-align: left;
	}

	.yunuo-list-04 {
		background: #f9fafe;
	}

	.yunuo-products-img {
		margin-left: 159px;
		width: 610px;
		height: 500px;
	}

	.yunuo-products-lf {
		width: 390px;
		padding-left: 50px;
	}

	.yunuo-products-lf>h1 {
		font-size: 32px;
		font-weight: bold;
		color: #333333;
		margin-bottom: 35px;
	}

	.yunuo-products-lf>p {
		font-size: 16px;
		font-weight: 500;
		color: #999999;
	}

	.yunuo-products-lf>h1 div {
		margin-bottom: 5px;
	}

	.yunuo-products {
		width: 1400px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		box-sizing: border-box;
		padding-bottom: 115px;
		padding-top: 115px;
	}

	.yunuo-list-05 {
		background: #ffffff;
	}

	.yunuo-products-boxs {
		width: 1400px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		box-sizing: border-box;
	}

	.yunuo-list-06 {
		background: #f9fafe;
	}

	.footer-left {
		width: 25.6%;
		position: relative;
	}
	.ewmBox {
		position: absolute;
		width: 149px;
		height: 174px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16653933951.png) no-repeat center;
		padding-top: 6px;
	}
	.ewmBox img {
		display: block;
		width: 149px;
		height: 140px;
		margin: 0 auto;
	}
	.ewmBox p {
		font-size: 14px;
		font-weight: 400;
		color: #222932;
		line-height: 14px;
		text-align: center;
	}
	
	.weixinBox {
		left: 36px;
		bottom: 25px;
	}
	.shipinBox {
		left: 90px;
		bottom: 25px;
	}
	

	.footer-contact-title {
		color: rgba(255, 255, 255, 0.5);

	}

	.hotline {
		margin-bottom: 22px;
	}

	.email {
		margin-bottom: 22px;
	}

	.footer-contact-tel {
		color: #fff;
		/* line-height: 40px; */
		margin-bottom: 16px;
		font-size: 24px;
	}

	.footer-contact-email {
		color: #fff;
		/* line-height: 40px; */
		margin-bottom: 50px;
		font-size: 24px;
	}

	.follow {
		font-size: 20px;
		font-weight: bold;
		color: #FFFFFF;
		padding-right: 22px;
	}

	.footer-share-logo-box {
		display: flex;
		align-items: center;
	}

	.list_sh {
		display: flex;
	}

	.list_sh>div:first-child {
		margin-right: 26px;
	}

	.footer-left-logo {
		width: 210px;
		height: 27px;
		margin-bottom: 55px;
	}

	.footer-share-er {
		display: block;
		width: 24px;
		height: 20px;
		cursor: pointer;
	}
</style>
