<template>
	<div class="digitization-item" @click="goPath(data.path)">
		<div class="digitization-img" :style="'background: url('+ data.img+') no-repeat center center'"></div>
		<h2>{{data.title}}</h2>
		<div class="line"></div>
		<p>{{data.textDetial}}</p>
	</div>
</template>

<script>
	export default {
		props: ["data"],
		name: 'BigHealthyBoxItem',
		data() {
			return {};
		},
		methods: {
			goPath(path) {
				this.$router.push(path)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			}
		}
	};
</script>

<style lang="less" scoped>
	.digitization-item {
		width: 318px;
		margin: 0 27px;
		cursor: pointer;
	}
	.digitization-img {
		background-repeat: no-repeat;
		width: 150px;
		height: 158px;
		margin: 0 auto;
		transition: all 0.5s ease-out 0s;
	}
	.digitization-item>.line {
		width: 68px;
		height: 2px;
		background: none;
		border-radius: 1px;
		margin: 22px auto 12px;
		transition: all 0.5s ease-out 0s;
	}
	.digitization-item>h2 {
		font-size: 26px;
		font-weight: bold;
		color: #222932;
		line-height: 26px;
		text-align: center;
	}
	.digitization-item>p {
		font-size: 18px;
		font-weight: 400;
		color: #777777;
		line-height: 30px;
		text-align: center;
	}
	.digitization-item:hover .digitization-img {
		transform: scale(1.1);
	}
	.digitization-item:hover .line {
		background: #1482EE;
	}
</style>
