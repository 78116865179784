<template>
	<div class="contactBox">
		<div class="contactItem" :class="choaseNum==1?'active': ''" @mouseover="mouseNavOver($event,1)" @mouseleave="mouseNavLeave($event)">
			<div class="img"></div>
			<p>关注我们</p>
		</div>
		<div class="contactItem" :class="choaseNum==2?'active': ''" @mouseover="mouseNavOver($event,2)" @mouseleave="mouseNavLeave($event)">
			<div class="img"></div>
			<p>联系我们</p>
		</div>
		<div class="contactItem" :class="choaseNum==3?'active': ''" @click="choaseTap()" @mouseover="mouseNavOver($event,3)" @mouseleave="mouseNavLeave($event)">
			<div class="img"></div>
			<p>TOP</p>
		</div>

		<div class="contactUs" v-if="choaseNum==2?'active': ''">
			<div class="phoneBox">
				<div class="phoneTop">
					<p>联系我们</p>
					<p>随时为您服务</p>
				</div>
				<div class="phoneBottom">
					<p>如需任何帮助请致电</p>
					<p>0532-85886309</p>
				</div>
			</div>

		</div>
		<div class="contactRwm" v-if="choaseNum==1?'active': ''">
			<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636392261.jpg" alt="">
			<p>关注雨诺公众号</p>
			<img src="https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636392392.png" alt="">
			<p>关注雨诺视频号</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		data() {
			return {
				choaseNum: null,
			};
		},
		methods: {
			choaseTap(type) {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (type == '1') {
					document.documentElement.scrollTop = 0;
				} else {
					var timer = setInterval(() => {
							document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 50; //一次减50往上滑动
							if (scrollTop <= 0) {
								this.choaseNum = null;
								clearInterval(timer);
							}
						},
					10);
				}
			},
			//头部鼠标悬停事件
			mouseNavOver($event,index) {
				this.choaseNum = index;
				$event.currentTarget.className = "contactItem active";

			},
			mouseNavLeave($event) {
				this.choaseNum = null;
				$event.currentTarget.className = "contactItem";
			},
		}
	}
</script>

<style scoped>
	.contactRwm {
		width: 110px;
		height: 255px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636384411.png) no-repeat center;
		position: absolute;
		left: -120px;
		top: -16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 7px 10px 0 0;
	}

	.contactRwm img {
		display: block;
		width: 99px;
		height: 97px;
	}

	.contactRwm p {
		font-size: 12px;
		font-weight: 400;
		color: #222932;
		line-height: 26px;
	}

	.contactUs {
		width: 160px;
		height: 130px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16635785821.png) no-repeat center;
		position: absolute;
		left: -160px;
		top: 47px;
	}

	.phoneTop {
		width: 100%;
		height: 56px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.phoneTop p {
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 16px;
	}

	.phoneBottom {
		width: 100%;
		height: 74px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.phoneBottom p:nth-child(1) {
		font-size: 12px;
		font-weight: 400;
		color: #222932;
		line-height: 17px;
	}

	.phoneBottom p:nth-child(2) {
		font-size: 16px;
		font-weight: 400;
		color: #1482EE;
		line-height: 17px;
	}

	.contactBox {
		width: 67px;
		height: 215px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		float: right;
		position: fixed;
		right: 32px;
		bottom: 100px;
		z-index: 1000;
	}

	.contactItem {
		width: 65px;
		height: 65px;
		border-radius: 7px;
		background: #F2F5FC;
		border: 1px solid #E0E4EE;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}

	.contactItem .img {
		display: block;
	}

	.contactItem:nth-child(1) .img {
		width: 27px;
		height: 21px;
		padding-top: 26px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166363903422.png) no-repeat center;
	}

	.contactItem:nth-child(2) .img {
		width: 23px;
		height: 24px;
		padding-top: 26px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166363901711.png) no-repeat center;
	}

	.contactItem:nth-child(3) .img {
		width: 25px;
		height: 15px;
		padding-top: 26px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/166363904733.png) no-repeat center;
	}

	.contactItem:nth-child(1).active .img {
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636389762.png) no-repeat center;
	}

	.contactItem:nth-child(2).active .img {
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636389441.png) no-repeat center;
	}

	.contactItem:nth-child(3).active .img {
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/16636389893.png) no-repeat center;
	}

	.contactItem p {
		height: 12px;
		font-size: 12px;
		line-height: 12px;
		font-weight: 400;
		color: #1482EE;
		text-align: center;
		padding-bottom: 8px;
	}

	.active {
		background: #1482EE;
	}

	.active p {
		color: #ffffff;
	}
</style>
