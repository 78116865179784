<template>
	<div class="product-center-m">
		<div class="product-center">
			<div class="product-menu">
				<div class="product-menu-item" v-for="(item,index) in boxList" :key="index" @mouseover="choaseSwiper(index)"
					:class="swiperIndex==index?'product-menu-item product-menu-item-active':'product-menu-item'">
					<p>{{item.title}}</p>
					<div class="product-menu-item-bg"></div>
				</div>
			</div>
			<div class="product-banner">
				<div class="swiper-container product-swiper-container" id="swiper2">
					<div class="swiper-wrapper">
						<div class="swiper-slide product-swiper-slide" v-for="(item,index) in boxList[swiperIndex].list"
							:key="index" @click="goPath(item.path)">
							<div class="slide-item">
								<img :src="item.img" alt="">
								<h2>{{item.h2}}</h2>
								<p>{{item.p}}</p>
								<div>了解详情</div>
							</div>
						</div>
					</div>
				</div>
				<div class="swiper-button-prev jian1"></div>
				<div class="swiper-button-next jian2"></div>
			</div>
		</div>
	</div>
</template>
<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	export default {
		name: "ProductSwiper",
		props: ["boxList"],
		data() {
			return {
				swiperIndex: 0
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.initSwiper()
			})
		},
		methods: {
			initSwiper() {
				this.mySwiper = new Swiper('#swiper2', {
					observer: true,
					slidesPerView: 3,
					autoplay: {
						dalay: 500,
						disableOnInteraction: false, //手动滑动之后不打断播放
					},
					speed: 500,
					// loop: true,
					observer: true, //修改swiper自己或子元素时，自动初始化swiper
					observeParents: true, //修改swiper的父元素时，自动初始化swiper
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				})
			},
			goPath(path) {
				this.$router.push(path)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
					10);
			},
			choaseSwiper(index) {
				this.swiperIndex = index;
			},
		}
	}
</script>
<style scoped>
	.product-center-m {
		width: 100%;
		background: #F6F9FE;
		padding-bottom: 62px;
	}

	.product-center {
		width: 1440px;
		margin: 0 auto;
		overflow: hidden;
	}

	.product-menu {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		width: 260px;
		height: 474px;
		background: #FFFFFF;
		float: left;
		margin-left: 20px;
	}

	.product-menu-item {
		width: 242px;
		height: 64px;
		background: #ffffff;
		position: relative;
		cursor: pointer;
	}

	.product-menu-item-bg {
		position: absolute;
		left: 0px;
		top: 0;
		width: 100%;
		height: 100%;
		background: #ffffff;
	}

	.product-menu-item p {
		width: 242px;
		height: 64px;
		font-size: 18px;
		font-weight: 500;
		color: #222932;
		line-height: 64px;
		text-align: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		transition: all 0.5s ease-out 0s;
	}

	.product-menu-item-active .product-menu-item-bg {
		background: #1482EE;
		left: -20px;
	}

	.product-menu-item-active p {
		color: #ffffff;
	}

	.product-menu-item::after {
		content: "";
		display: block;
		width: 242px;
		height: 64px;
		background: none;
		position: absolute;
		top: 0;
		left: 0;
		transition: all 0.5s ease-out 0s;
	}

	/* 轮播部分 */
	.product-banner {
		margin-left: 56px;
		float: left;
		width: 1060px;
		position: relative;
	}

	.jian1,
	.jian2 {
		display: block;
		z-index: 100000;
		position: absolute;

	}

	.jian1 {
		left: -44px !important;
	}

	.jian2 {
		right: -44px !important;
	}

	.swiper-button-next:after {
		content: '' !important;
		display: block;
		width: 22px;
		height: 33px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663116897next.png) no-repeat center;
	}

	.swiper-button-prev:after {
		content: '' !important;
		display: block;
		width: 22px;
		height: 33px;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663116954prev.png) no-repeat center;
	}

	.swiper-button-prev.swiper-button-disabled {
		content: '' !important;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663116968prev1.png) no-repeat center;
	}

	.swiper-button-next.swiper-button-disabled {
		content: '' !important;
		background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663116936next1.png) no-repeat center;
	}

	.swiper-button-prev.swiper-button-disabled:after {
		background: none;
	}

	.swiper-button-next.swiper-button-disabled:after {
		background: none;
	}

	.product-swiper-container {
		width: 1060px;
	}

	.product-swiper-slide {
		width: auto;
		/* width: 325px; */
		/* width: 1118px; */
	}

	.slide-item {
		width: 245px;
		height: 475px;
		background: #FFFFFF;
		padding: 0 40px;
		position: relative;
		cursor: pointer;
		margin: 0 20px;
		transition: all 0.5s ease-out 0s;
	}

	.slide-item img {
		display: block;
		width: 91px;
		height: 58px;
		margin: 0 auto 26px;
		padding-top: 27px;
		transition: all 0.5s ease-out 0s;
	}

	.slide-item h2 {
		font-size: 24px;
		font-weight: bold;
		color: #222932;
		line-height: 26px;
		text-align: center;
		transition: all 0.5s ease-out 0s;
	}

	.slide-item p {
		font-size: 16px;
		font-weight: 400;
		color: #777777;
		line-height: 30px;
		margin: 34px 0 54px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical;
		transition: all 0.5s ease-out 0s;
	}

	.slide-item div {
		position: absolute;
		bottom: 22px;
		left: 50%;
		width: 122px;
		height: 38px;
		background: #1482EE;
		border-radius: 6px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 38px;
		text-align: center;
		margin-left: -61px;
		transition: all 0.1s ease-out 0s;
	}

	.slide-item:hover {
		background: #1482EE;
	}

	.slide-item:hover img {
		transform: scale(0);
	}

	.slide-item:hover h2 {
		color: #ffffff;
		transform: translateY(-40px);
	}

	.slide-item:hover p {
		-webkit-line-clamp: 11;
		color: #ffffff;
		transform: translateY(-40px);
	}

	.slide-item:hover div {
		transform: scale(0);
	}
</style>
