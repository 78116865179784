<template>
	<div class="list-head">
		<div class="product-introduction">
			<h1 class="modular-title"
				:style="(color=='white'? 'color:#ffffff;' : 'color:#222A32;')+(fine=='xi'? 'font-size: 20px;color:#777777;font-weight: 400;' : 'font-size: 36px;')">
				{{title}}</h1>
			<p class="product-introduction-p">{{textDetial}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["title", "textDetial", "color", "fine"],
		name: 'ListHead',
		data() {
			return {};
		},
	}
</script>

<style lang="less" scoped>
	.modular-title {
		font-size: 36px;
		font-weight: bold;
		color: #222A32;
		margin-bottom: 55px;
		text-align: center;
		padding-top: 120px;
	}

	.product-introduction-p {
		font-size: 18px;
		font-weight: 400;
		color: #777777;
		line-height: 31px;
		text-align: center;
	}
</style>
