<template>
	<div class="news-box">
		<div class="news-box-left" @click="goPage(boxList.path)">
			<div class="img-box">
				<img :src="boxList.img" alt="">
			</div>
			<span>{{boxList.time}}</span>
			<h2>{{boxList.title}}</h2>
		</div>
		<div class="news-box-right">
			<ul>
				<HomeNewsItem v-for="(item,index) in boxList.list" :key="index" :data="item"></HomeNewsItem>
			</ul>
		</div>
		
	</div>
</template>

<script>
	import HomeNewsItem from "./HomeNewsItem.vue";
	export default {
		props: ["boxList"],
		name: 'ListBox',
		data() {
			return {};
		},
		components: {
			HomeNewsItem,
		},
		methods: {
			goPage(src) {
				this.$router.push(src)
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				var timer = setInterval(() => {
						document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 500; //一次减50往上滑动
						if (scrollTop <= 0) {
							clearInterval(timer);
						}
					},
				10);
			}
		}
	};
</script>

<style scoped>
	.news-box {
		display: flex;
		justify-content: space-between;
	}
	
	.news-box-left {
		text-align: left;
		cursor: pointer;
	}
	
	.news-box-left .img-box {
		width: 630px;
		height: 334px;
		overflow: hidden;
	}
	
	.news-box-left .img-box img {
		display: block;
		width: 630px;
		height: 334px;
		transition: all 0.5s ease-out 0s;
	}
	
	.news-box-left:hover .img-box img {
		transform: scale(1.1);
	}
	.news-box-left:hover h2 {
		width: 630px;
		color: #1482EE;
	}
	.news-box-left:hover span {
		color: #1482EE;
	}
	
	.news-box-left span {
		display: inline-block;
		font-size: 20px;
		font-weight: 400;
		color: #575D6C;
		line-height: 20px;
		margin: 26px 0;
	}
	
	.news-box-left h2 {
		width: 630px;
		font-size: 20px;
		font-weight: 400;
		color: #222932;
		line-height: 20px;
	}
	.news-box-right ul {
		width: 722px;
	}
</style>