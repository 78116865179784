<template>
	<Header :isShowTop="isShowTop" />
	<router-view />
	<Footer />
	<ContactBox ref="contBox"></ContactBox>
</template>
<script>
	import Header from '@/components/common/Header.vue'
	import Footer from '@/components/common/Footer.vue'
	import ContactBox from '@/components/common/ContactBox.vue'
	export default {
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isShowTop: false,
				isReload: true
			}
		},
		components: {
			Header,
			Footer,
			ContactBox
		},
		mounted() {
			if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
				window.location.href = "https://www.romens.cn/OLD/MobileHtml/index.html";
			}
			// 监听横屏竖屏变化
			window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.isPortrait,
				false);
			var t = window.devicePixelRatio; // 获取屏幕的缩放 125% -> 1.25 150% -> 1.5
			var ping = window.screen.width * window.devicePixelRatio; // 获取屏幕分辨率的宽度
			console.log('缩放比例', t)
			console.log('分辨率长度', ping)

			if (t == 2 && ping <= 1640) {
				console.log('苹果本')
				document.body.style.zoom = 0.5; // 小屏将屏幕缩放为80%
			}
			if (t == 2 && ping >= 1920) {
				console.log('苹果本')
				document.body.style.zoom = 0.8; // 小屏将屏幕缩放为80%
			}
			if (t == 1.5 && ping >= 1920) {
				console.log('小屏且放大1.5倍')
				document.body.style.zoom = 0.8; // 小屏将屏幕缩放为80%
			}
			if (ping <= 1500) {
				document.body.style.zoom = 0.8; // 小屏将屏幕缩放为80%
			}
			if (t == 2 && ping == 2048) {
				console.log('pad  mini')
				document.body.style.zoom = 0.7; // 小屏将屏幕缩放为80%
			}
			if ((window.orientation === 180 || window.orientation === 0) || (window.orientation === 90 || window.orientation === -90)) {
				console.log('这是pad端')
				// window.location.href = "https://www.romens.cn/OLD/MobileHtml/index.html";
			}
		},
		created() {
			this.$router.beforeEach((to, from, next) => {
				var str = to.name;
				if (str === undefined) {
					window.location.href = 'http://romens.cn'
				}
				var isShowTop = str.indexOf('NewsInfor') != -1 || str.indexOf('NewsCenter') != -1;
				this.isShowTop = isShowTop;
				next()
				if (str == "NewsInfor1" || str == "NewsInfor2") {
					var id = 1
					this.$refs.contBox.choaseTap(id)
				}
			})


			this.$nextTick(() => {
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
			})
			let imgList = []
			const files = require.context("./assets/logs", true,/\.*\.jpg|jpeg|png$/).keys();
			for (let i = 0; i < files.length; i++) {
				const e = files[i];
				var objImg = {
					"img": require("./assets/logs/" + e.split("./")[1])
				}
				imgList.push(objImg)
			}
			this.$store.commit('imgList',imgList)
		},
		methods: {
			reload() {
				this.isReload = false;
				this.$nextTick(() => {
					this.isReload = true;
				})
			},
			isPortrait() {
				if (window.orientation === 180 || window.orientation === 0) {
					console.log('竖屏');
					this.reload()
					location. reload()
					return true;
				}
				if (window.orientation === 90 || window.orientation === -90) {
					console.log('横屏');

					location. reload()
					this.reload()
					return false;
				}
			}
		}
	}
</script>
<style lang="less" scoped>
	/*每个页面公共css */
	@import '@/assets/reset.css';
</style>
