<template>
	<!-- Video组件 -->
	<div id="common-video">
		<div class="h-100">
			<video ref="myVideo" :poster="poster" :src="src" autoplay="autoplay" :controls="controls" class="video-box"></video>
			<img src="https://www.inspur.com/lcjtww/uiFramework/commonResource/image/2021012115310018030.png" alt=""
				@click="operateVideo" class="pointer operate-btn" :class="{ 'fade-out': videoState }" />
			<div class="g_close" @click="vidoOff">×</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CommonVideo",
		data() {
			return {
				videoState: true, // 视频播放状态
			};
		},
		/**
		 * @param poster 展示图
		 * @param src 视频资源
		 * @param controls 是否显示控件
		 */
		props: {
			poster: {
				type: String,
				required: false,
				default: "",
			},
			src: {
				type: String,
				required: true,
			},
			controls: {
				type: Boolean,
				required: false,
				default: true,
			},
			isFlag: {
				type: Boolean,
				required: true,
			},
		},
		mounted() {},
		methods: {
			// 操作视频播放、暂停
			operateVideo() {
				if (!this.src) {
					this.$message({
						message: "暂无视频资源，请查看其他视频！"
					});
					return false;
				}
				console.log(this.$refs.myVideo.paused);
				if (this.$refs.myVideo.paused) {
					this.$refs.myVideo.play();
					this.videoState = true;
				} else {
					this.$refs.myVideo.pause();
					this.videoState = false;
				}
			},
			vidoOff() {
				this.$emit("sdjf", !this.isFlag);
			},
		},
		watch: {
			// 监听操作
			isFlag(val) {
				this.videoState = val;
			},
		},
	};
</script>

<style scoped>
	#common-video {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.8);
		z-index: 1000000;
	}

	.h-100 {
		position: relative;
		width: 50%;
		height: auto;
	}

	.video-box {
		box-sizing: border-box;
		border: 0;
		display: block;
		width: 100%;
		height: auto;
		outline: none !important;
	}
	video::-webkit-media-controls-play-button {
		visibility: hidden;
	}

	.operate-btn {
		display: block;
		width: 60px;
		height: 60px;
		position: absolute;
		top: calc(50% - 30px);
		left: calc(50% - 30px);
	}

	.operate-btn:hover {
		opacity: 0.8;
	}

	.fade-out {
		opacity: 0;
	}

	.g_close {
		position: absolute;
		font-size: 40px;
		font-weight: bold;
		top: -50px;
		right: -50px;
		color: #fff;
		cursor: pointer;
	}
</style>
