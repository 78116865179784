<template>
    <div class="product-introduction">
		<h1 class="modular-title">{{title}}</h1>
		<p class="product-introduction-p">{{textDetial}}</p>
		<div class="contact" @click="contactUs()">
			<span>联系我们</span>
		</div>
	</div>
</template>
 
<script>
export default {
  props: ["title", "textDetial"],
  name:'ListHead',
  data() {
    return {};
  },
  methods: {
	contactUs() {
		this.$router.push('/ContactUs')
		let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		var timer = setInterval(() => {
				document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 5000; //一次减50往上滑动
				if (scrollTop <= 0) {
					clearInterval(timer);
				}
			},
		10);
	}  
  }
};
</script>
 
<style lang = "less" scoped>
.product-introduction {
	padding: 152px 0 114px;
	background: url(https://romenstech.oss-cn-qingdao.aliyuncs.com/shop/88-100/singleimg/1663206325contact.png) no-repeat center center;
	background-size: cover;
}	
.modular-title {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 45px;
    text-align: center;
}
.product-introduction-p {
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    line-height: 31px;
    text-align: center;
	margin-bottom: 83px;
}
.contact {
	width: 214px;
	height: 50px;
	border: 2px solid #FFFFFF;
	border-radius: 12px;
	margin: 0 auto;
	text-align: center;
	cursor: pointer;
}
.contact span {
	font-size: 22px;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 50px;
	transition: all 0.5s ease-out 0s;
}

</style>