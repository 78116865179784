<template>
	<div style="">
		<div class="swiper-container" id="swiper1">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(item,index) in boxList" :key="index">
					<img :src="item.img" alt="" :data-path="item.path" />
					<div class="banner-m" :data-path="item.path">
						<h2 :data-path="item.path" :style="item.type == 2?'text-align: center;margin-bottom:70px;':''">
							{{item.title}}</h2>
						<div :data-path="item.path" class="line" v-if="item.type == 1"></div>
						<p :data-path="item.path" :style="item.type == 2?'text-align: center':''">{{item.infor}}</p>
					</div>
				</div>
			</div>
			<!-- 如果需要导航按钮 -->
			<div class="swiper-pagination"></div>
		</div>
	</div>
</template>
<script>
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	export default {
		name: "Smallswiper",
		props: ["boxList"],
		mounted() {
			this.$nextTick(() => {
				this.initSwiper()
			})
		},
		methods: {
			initSwiper() {
				var that = this;
				new Swiper('#swiper1', {
					effect: 'fade',
					loop: true, // 循环模式选项
					speed: 500,
					autoplay: { // 自动滑动
						delay: 3000, //1秒切换一次
						disableOnInteraction: false
					},
					pagination: { // 如果需要分页器
						el: '.swiper-pagination',
						clickable: true,
					},
					pauseOnMouseEnter: true,
					on: {
						click: function(e) {
							if (e.target.dataset.path != '') {
								that.$router.push(e.target.dataset.path)
							}
						}
					}
				})
			},
		}
	}
</script>
<style scoped>
	.swiper-pagination-bullet {
		width: 16px !important;
		height: 16px !important;
		border-radius: 0% !important;
	}

	:root {
		--swiper-theme-color: #f6f9fe !important;
	}

	.swiper-slide {
		position: relative;
	}

	.swiper-slide img {
		width: 100%;
	}

	.banner-m {
		width: 1440px;
		height: 100%;
		margin: 0 auto;
		position: absolute;
		left: 50%;
		top: 0;
		margin-left: -720px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.banner-m h2 {
		font-size: 100px;
		font-weight: 800;
		color: #FFFFFF;
		line-height: 105px;
	}

	.banner-m .line {
		width: 636px;
		height: 4px;
		background: #FFFFFF;
		margin: 23px 0 36px;
	}

	.banner-m p {
		font-size: 42px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 42px;
	}
</style>
