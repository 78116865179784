<template>
	<div class="digitization-box">
		<BigHealthyBoxItem v-for="item in boxList" :key="item.id" :data="item"></BigHealthyBoxItem>
	</div>
</template>

<script>
	import BigHealthyBoxItem from "./BigHealthyBoxItem.vue";
	export default {
	  props: ["boxList"],
	  name:'BigHealthyBox',
	  data() {
	    return {};
	  },
	  components: {
	    BigHealthyBoxItem,
	  },
	};
</script>

<style lang="less" scoped>
	.digitization-box {
		display: flex;
		justify-content: center;
		margin-top: 85px;
		padding-bottom: 80px;
	}
</style>
